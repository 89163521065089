import $ from 'jquery';

// Support of rails's default {remote: true} on form, which enable ajax calls
export default function initializeGlobalAjaxRailsForm() {
  
  // Immediately after ajax submit
  $(document).on(
    'ajax:before',
    'form',
    function (event) {
      const $form = $(this);
      
      // Find the last submit button
      const submitButton = $form.find('input[type="submit"]').last()

      // Mark the submit button as loading
      submitButton.addClass("btn-loading")
    }
  );

  // After the ajax request is done or failed
  $(document).on(
    'ajax:complete',
    'form',
    function (event) {
      const $form = $(this);
      const parsedResponse = JSON.parse(event.detail[0].response);
      const submitButton = $form.find('input[type="submit"]').last()
      submitButton.removeClass("btn-loading")
      if (parsedResponse.success) {
        if (parsedResponse.redirect) {
          window.location = parsedResponse.redirect;
        } else if (parsedResponse.form) {
          $form.replaceWith(parsedResponse.form);
        }
      } else if (!parsedResponse.success) {
        
        if (parsedResponse.form) {
          $form.replaceWith(parsedResponse.form);
        }
      }
    }
  );
  
  // Traditional form
  $(document).on('submit', 'form.simple_form', function(event) {
    const $form = $(this);

    // Find the last submit button
    const submitButton = $form.find('input[type="submit"]').last()

    // Mark the submit button as loading
    submitButton.addClass("btn-loading")
  });

}
