import consumer from "./consumer";
import DataTable from "datatables.net-bs5";

export function startLiveMarketsSubscription(table, companySlug, nodes) {
  const subscription = consumer.subscriptions.create({
    channel: "LiveMarketsChannel",
    companySlug,
  }, {
    received(message) {
      if (message.message_type === 'refresh_live_markets') {
        this.updateTable(message.tickers);
      } else if (message.message_type === 'refresh_summary_data') {
        this.updateSummary(message)
      } else {
        console.warn(`Received unexpected message_type: ${message.message_type}`);
      }
    },

    updateTable(tickers) {
      table.clear()
        .rows
        .add(tickers)
        .draw();
    },

    updateSummary(message) {
      const formatPrice = DataTable.render.number(null, null, 2, '$').display;
      const formatFdvOrVolume = DataTable.render.number(null, null, 0, '$').display;
      nodes.summaryHeaderPriceNode.text(formatPrice(message.base_asset_usd_price));
      nodes.summaryHeaderFdvNode.text(formatFdvOrVolume(message.latest_fully_diluted_valuation_usd));
      nodes.summaryHeaderVolumeNode.text(formatFdvOrVolume(message.total_24_hour_volume_usd));
      nodes.marketsTableContainerNode.data('totalVolumeUsd', message.total_24_hour_volume_usd);
    }
  });
  return () => subscription.unsubscribe();
}