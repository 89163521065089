import $ from "jquery";
import DataTable from "datatables.net-bs5";
import {startLiveMarketsSubscription} from "../../channels/live_markets";

const MARKETS_TABLE_CONTAINER_SELECTOR = '.v-js-companies-live-report-markets-table';
const MARKETS_TABLE_SELECTOR = '.js-companies-live-report-markets-table';

const SUMMARY_HEADER_PRICE_SELECTOR = '.js-shared-reports-summary-header-price';
const SUMMARY_HEADER_FDV_SELECTOR = '.js-shared-reports-summary-header-fdv';
const SUMMARY_HEADER_VOLUME_SELECTOR = '.js-shared-reports-summary-header-volume';

export function initializeCompaniesLiveReportMarketsTable() {
  const marketsTableContainerNode = $(MARKETS_TABLE_CONTAINER_SELECTOR);
  if (marketsTableContainerNode.length === 0) {
    return;
  }
  const companySlug = marketsTableContainerNode.data('companySlug');
  const table = createDataTable(marketsTableContainerNode);
  const nodes = {
    marketsTableContainerNode,
    summaryHeaderPriceNode: $(SUMMARY_HEADER_PRICE_SELECTOR),
    summaryHeaderFdvNode: $(SUMMARY_HEADER_FDV_SELECTOR),
    summaryHeaderVolumeNode: $(SUMMARY_HEADER_VOLUME_SELECTOR),
  }
  return startLiveMarketsSubscription(table, companySlug, nodes);
}

function createDataTable(marketsTableContainerNode) {
  return new DataTable(MARKETS_TABLE_SELECTOR, {
    info: false,
    paging: false,
    searching: false,
    // set initial ordering to "24h Volume" descending
    order: [[6, 'desc']],
    fixedHeader: {
      header: true
    },
    data: marketsTableContainerNode.data('initialValue'),
    rowCallback(row) {
      $(row).addClass('font-size-16px text-color-mf-secondary text-nowrap')
    },
    headerCallback(header) {
      $(header).addClass('font-size-16px text-nowrap')
    },
    columns: [
      {
        title: 'Exchange',
        data: 'exchange_name',
        render(data, type, row) {
          return `<img class="mr-4px" height=20 width=20 src="${row.logo_url}"/>` +
            `<span style="vertical-align: middle">${data}</span>`;
        }
      }, {
        title: 'Pair',
        data(row) {
          return `${row.base_asset_symbol}/${row.counter_asset_symbol}`
        },
        render(data, type, row) {
          return `<a href="${row.trade_url}">${data}</a>`
        }
      }, {
        title: 'Price',
        data: 'last_price_usd',
        render: DataTable.render.number(null, null, 2, '$'),
      }, {
        title: 'Spread',
        data: 'bid_ask_spread_percentage',
        render: DataTable.render.number(null, null, 2, null, '%'),
      }, {
        title: '+2% Depth',
        data: 'two_percent_depth_above',
        render: DataTable.render.number(null, null, 0, '$'),
      }, {
        title: '-2% Depth',
        data: 'two_percent_depth_below',
        render: DataTable.render.number(null, null, 0, '$'),
      }, {
        title: '24h Volume',
        data: 'asset_pair_24_hour_volume_usd',
        render: DataTable.render.number(null, null, 0, '$'),
      },
      {
        title: 'Volume %',
        data(row) {
          return row.asset_pair_24_hour_volume_usd / marketsTableContainerNode.data('totalVolumeUsd') * 100.0;
        },
        render: DataTable.render.number(null, null, 2, null, '%'),
      }
    ]
  });
}