import $ from 'jquery';

// Helper to track specific insertion into to the DOM
export function onDomInsertion(containerSelector, elementSelector, callback) {
  const onMutationsObserved = function (mutations) {
    mutations.forEach(function (mutation) {
      if (mutation.addedNodes.length) {
        const elements = $(mutation.addedNodes).find(elementSelector);
        for (let i = 0, len = elements.length; i < len; i++) {
          callback(elements[i]);
        }
      }
    });
  };

  const target = $(containerSelector)[0];
  const config = { childList: true, subtree: true };
  const MutationObserver =
    window.MutationObserver || window.WebKitMutationObserver;
  const observer = new MutationObserver(onMutationsObserved);
  observer.observe(target, config);
}
