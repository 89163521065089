// Import JS + Styles
import selectize from '@selectize/selectize';
import '@selectize/selectize/dist/js/selectize.min.js';

// Jquery
import $ from 'jquery';

// Helper function
import { onDomInsertion } from './utils/dom.js';

function initializeSelectize($element) {
  $element.selectize({ copyClassesToDropdown: false });
}

export function destroyGlobalSelectizeInput() {
  $('select.js-selectize-input-picker').each((index, select) => {
    const $select = $(select);
    const selectizeInstance = $select[0].selectize;
    if (selectizeInstance) {
      selectizeInstance.destroy();
    }
  });
}

export function initializeGlobalSelectizeInput() {
  onDomInsertion('body', '.js-selectize-input-picker', function (element) {
    const $element = $(element);
    if ($element.is('select')) {
      initializeSelectize($element);
    }
  });

  // Initialize all present select with selectize class
  initializeSelectize($('select.js-selectize-input-picker'));
}
